@define-extend container {
    content: '';
    position: absolute;
    display: inline-block;
}

@define-extend blink-anim {
    background-color: white;
    animation-name: blinkwhite;
    animation-timing-function: steps(1);
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
}

@define-mixin blink $color {
    0% {
        background-color: $(color);
        color: black;
    }
    50% {
        background-color: black;
        color: white;
    }
}

@keyframes blinkwhite {
    @mixin blink white;
}

@keyframes blinklime {
    @mixin blink lime;
}

@keyframes blinkdarkred {
    @mixin blink darkred;
}

@keyframes blinkblue {
    @mixin blink blue;
}

@keyframes blinkblack {
    @mixin blink black;
}

@keyframes ribbonblink {
    0% {
        text-shadow: 0 0 5px red;
    }
    50% {
        text-shadow: 0 0 12px lime;
    }
}

html, body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    background-color: grey;
}

h1 {
    font-family: monospace;
    font-size: 58px;
    font-weight: normal;
    text-align: center;
    line-height: 1;
    margin: 12vh 0;
    &.title {
        position: absolute;
        color: white;
        top: 0;
        width: 100%;
    }
}

#terminal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.93);
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.75);
    color: white;
    font-family: monospace;
    font-size: 1rem;
    padding: 1rem;
    margin: 1rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;

    a {
        color: white;
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: .4em 0;
        text-align: left;
    }

    h1 {
        font-size: 2rem;
    }

    .label::before {
        content: '>';
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1em;
    }

    .output {
        color: darkGray;
        &.error {
            color: darkred;
        }
        small {
            color: dimgray;
        }
    }
}

#cli {
    position: relative;
    white-space: pre-wrap;
    span {
        &.cursor {
            position: relative;
            color: black;
            @extend blink-anim;
            &.pulse, &.pulse.underline::before, &.pulse.thin::before {
                animation-timing-function: linear;
            }
            &.green, &.green.underline::before,  &.green.thin::before {
                background-color: lime;
                animation-name: blinklime;
            }
            &.red, &.red.underline::before, &.red.thin::before {
                background-color: darkred;
                animation-name: blinkdarkred;
            }
            &.blue, &.blue.underline::before, &.blue.thin::before {
                background-color: blue;
                animation-name: blinkblue;
            }
            &.underline {
                animation-name: blinkblack;
                &::before {
                    @extend container;
                    height: 2px;
                    width: 1ch;
                    bottom: 0;
                    @extend blink-anim;
                }
            }
            &.thin {
                animation-name: blinkblack;
                &::before {
                    @extend container;
                    height: 1em;
                    width: 2px;
                    left: 0;
                    @extend blink-anim;
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    top: 10rem;
    right: -2rem;
    transform-origin: right top;
    transform: rotate(45deg);
    background-color: black;
    box-shadow: 0 0 10px green;
    font-family: monospace;
    text-align: center;
    a {
        display: block;
        margin: 1px 0;
        padding: .5rem 4rem;
        border: 1px solid green;
        text-shadow: 0 0 5px white;
        text-decoration: none;
        color: lime;
        animation-name: ribbonblink;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
        animation-fill-mode: none;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
}
